var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"700","max-width":"100%","persistent":"","scrollable":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{staticClass:"mx-auto",class:{
      'w-vinci-form-modal': true,
      'w-vinci-form-modal--mobile': _vm.$voicer.isMobile,
    }},[_c('v-toolbar',{staticClass:"color: #fbde51",attrs:{"flat":""}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.open = false}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-close-thick")])],1)],1),_vm._v(" "),_c('v-img',{staticClass:"pointer",attrs:{"src":_vm.$voicer.isMobile
          ? require('~/assets/images/popup_mobile_partenaires.png')
          : require('~/assets/images/popup_desktop_partenaires.png'),"alt":"image popup","max-height":_vm.$voicer.isMobile ? 368 : undefined},on:{"click":function($event){return _vm.handleOpen(
          'https://www.ecouteetvous.com/universe/65cb3497f82f1c2d9391e661'
        )}}}),_vm._v(" "),_c('div',{staticClass:"text-alternate pa-3 primary--text"},[_vm._v("\n      Découvrez tous les épisodes consacrés aux missions et activités de nos\n      partenaires associatifs.\n    ")]),_vm._v(" "),_c('div',{staticClass:"btn"},[_c('v-btn',{ref:"btn",staticClass:"ma-2 white--text",attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.handleOpen()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#ffffff"}},[_vm._v("mdi-volume-high")]),_vm._v("\n        ÉCOUTER\n      ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }