<template>
  <v-dialog v-model="open" width="700" max-width="100%" persistent scrollable>
    <v-card
      class="mx-auto"
      :class="{
        'w-vinci-form-modal': true,
        'w-vinci-form-modal--mobile': $voicer.isMobile,
      }"
    >
      <v-toolbar flat class="color: #fbde51">
        <v-spacer />
        <v-btn icon small @click.stop="open = false">
          <v-icon color="primary">mdi-close-thick</v-icon>
        </v-btn>
      </v-toolbar>
      <v-img
        class="pointer"
        :src="
          $voicer.isMobile
            ? require('~/assets/images/popup_mobile_partenaires.png')
            : require('~/assets/images/popup_desktop_partenaires.png')
        "
        alt="image popup"
        :max-height="$voicer.isMobile ? 368 : undefined"
        @click="
          handleOpen(
            'https://www.ecouteetvous.com/universe/65cb3497f82f1c2d9391e661'
          )
        "
      />
      <div class="text-alternate pa-3 primary--text">
        Découvrez tous les épisodes consacrés aux missions et activités de nos
        partenaires associatifs.
      </div>
      <div class="btn">
        <v-btn
          ref="btn"
          color="primary"
          depressed
          class="ma-2 white--text"
          @click="handleOpen()"
        >
          <v-icon color="#ffffff" class="mr-2">mdi-volume-high</v-icon>
          ÉCOUTER
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import localstore from 'store'
export default {
  data() {
    const lastOpen = localstore.get('exc_dialog_last_open') || null
    let open = false
    if (!lastOpen) open = true
    else {
      const now = new Date()
      const lastOpenDate = new Date(lastOpen)
      lastOpenDate.setDate(lastOpenDate.getDate() + 1)
      if (lastOpenDate < now) {
        open = true
      }
    }
    if (open === true) {
      localstore.set('exc_dialog_last_open', new Date())
      this.$store.dispatch('player/closePersistantPlayer')
    }
    return {
      open,
    }
  },
  watch: {
    open(status) {
      if (status === false) {
        this.$store.dispatch('player/openPersistantPlayer')
      }
    },
  },
  methods: {
    handleClick() {
      window.open(
        'https://www.ecouteetvous.com/universe/65cb3497f82f1c2d9391e661'
      )
      this.open = false
      this.$emit('open-form')
    },
    handleOpen() {
      window.open(
        'https://www.ecouteetvous.com/universe/65cb3497f82f1c2d9391e661'
      )
    },
  },
}
</script>
<style lang="scss">
.btn {
  text-align: center;
  padding-bottom: 7px;
}
.pointer:hover {
  cursor: pointer;
}
.headline {
  font-size: 15px;
}
.w-vinci-form-modal {
  &--mobile {
    display: flex;
    flex-direction: column;
    .v-card__text {
      flex-grow: 1;
      position: relative;
    }
  }
  &__body2 {
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .body-2 {
    justify-content: center;
    text-align: center;
    align-items: center;
    display: inherit;
  }
  .body-1 {
    justify-content: center;
    text-align: center;
    align-items: center;
    display: inherit;
  }
  .text-alternate {
    color: #184a8b;
    text-align: center;
  }
}
</style>
