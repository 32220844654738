<template>
  <div class="w-block__footer">
    <!-- footer block -->
    <div class="mt-1 mb-4">
      <div class="list-footer-images">
        <img
          :src="require('~/assets/images/action_cancer.png')"
          height="50"
          alt="logo-action-cancer-47"
          class="mr-4"
        />
        <img
          :src="require('~/assets/images/logo_agen_agglo.png')"
          height="48"
          alt="logo-agen-agglo"
          class="mr-4"
        />
        <img
          :src="require('~/assets/images/centre.png')"
          height="49"
          alt="logo-centre"
          class="mr-4 mt-1"
        />
        <img
          :src="require('~/assets/images/comite_cancer.png')"
          height="50"
          alt="logo-comite-cancer"
          class="mr-4 mt-1"
        />
        <img
          :src="require('~/assets/images/ligue.jpg')"
          height="49"
          alt="logo-ligue-contre-le-cancer"
          class="mr-4 mt-1"
        />
      </div>
    </div>
    <div class="body-2 mb-2">
      Réalisé en toute indépendance avec le soutien institutionnel de
    </div>
    <div class="list-footer-images">
      <img
        :src="require('~/assets/images/roche.png')"
        height="38"
        alt="logo-roche"
        class="mr-4"
      />
      <img
        :src="require('~/assets/images/logo_amgen.png')"
        height="48"
        alt="logo-amgen"
        class="mr-4 img-footer-amgen"
      />
      <img
        :src="require('~/assets/images/pfizer.png')"
        height="38"
        alt="logo-pfizer"
        class="mr-4"
      />
    </div>
    <exc-dialog />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ExcDialog from '~/excentrics/components/ExcDialog'
export default {
  components: {
    ExcDialog,
  },
  computed: {
    ...mapGetters({
      isPersistantVisible: 'player/isPersistantVisible',
    }),
  },
  methods: {
    handleOpen(url) {
      window.open(url, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.img-footer-amgen {
  height: 24px !important;
}
.list-footer-images {
  display: flex;
  align-items: center;
  margin-top: 1em;
  flex-wrap: wrap;
}
</style>
