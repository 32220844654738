var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-block__pre-heading",class:{ 'ml-2 mr-2 mt-3': _vm.$voicer.isMobile }},[(_vm.$route.name === 'index')?_c('v-container',{staticClass:"pa-2"},[_c('v-card',{staticClass:"pa-2",attrs:{"color":"primary","dark":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card-text',{staticClass:"w-block__body2 text-alternate",class:{
            'pa-2': _vm.$voicer.isMobile === false,
            'pa-1': _vm.$voicer.isMobile === true,
            'subtitle-2': _vm.$voicer.isMobile,
            'subtitle-2': _vm.$voicer.isMobile === false,
          }},[_vm._v("\n          Ecoute & Vous, le podcast d'information sur les cancers, leurs\n          traitements et tous les \"à coté\" des protocoles de soins\n        ")]),_vm._v(" "),_c('div',[_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.handleOpen(
                'https://www.facebook.com/Ecoute-Vous-103395135670434'
              )}}},[_c('v-icon',{attrs:{"color":"#d21233"}},[_vm._v("mdi-facebook")])],1),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.handleOpen('https://www.instagram.com/ecoute_et_vous/?hl=fr')}}},[_c('v-icon',{attrs:{"color":"#d21233"}},[_vm._v("mdi-instagram")])],1),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.handleOpen('https://www.linkedin.com/company/ecouteetvous/')}}},[_c('v-icon',{attrs:{"color":"#d21233"}},[_vm._v("mdi-linkedin")])],1)],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }