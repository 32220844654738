<template>
  <div class="w-block__post-featured">
    <v-container v-if="$route.name === 'index'" class="pa-2">
      <v-card color="#f9eae1" class="pa-2">
        <v-flex xs12>
          <v-card-text
            class="w-block__body2 text-alternate"
            :class="{
              'pa-2': $voicer.isMobile === false,
              'pa-1': $voicer.isMobile === true,
              'subtitle-2': $voicer.isMobile,
              'subtitle-2': $voicer.isMobile === false,
            }"
          >
            Des remarques ? Des suggestions ? Sujets à aborder ?
            <div>
              Partagez les en remplissant le
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSepEV90UW7o41p3eppuXICBu6AczULIeuHZCOBIrLP7Ljs0KA/viewform"
                target="_blank"
                >questionnaire</a
              >
            </div>
          </v-card-text>
        </v-flex>
      </v-card>
    </v-container>
  </div>
</template>
<script>
export default {
  methods: {
    handleOpen(url) {
      this.$spoke.eventManager.emit('click_link', {
        to: url,
        from_block: 'post-featured',
      })
      window.open(url, '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
.w-block__post-featured {
  display: flex;
  justify-content: center;
  text-align: center;
  color: #3e2723;
}
.v-card {
  border-radius: 8px;
  cursor: pointer;
}
</style>
